/* Copyright 2019 Greyskies. All Rights Reserved. */

@import 'config/variables-fns';

.notifcations-counter{
  width: 12px;
  height: 12px;
  color:@lightText;
  background:@mainColor;
  border-radius: 12px;
  font-size: 10px;
  padding-left: 2px;
  position: absolute;
  opacity: 0.95;
  bottom: 26px;
  right: 7px;
}

.netflow_multiple_select{
  margin-bottom: 20px!important;
}

.export_name{
  word-wrap: break-word;
}

.exports-wrapper{
  max-height: 300px;
  height: 300px ;
}