/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../config/variables-fns';

.forgetPasswordPageWrapper {
  .noFooterBorder {
    display: grid;

    .cancelForm-btn {
      order: 3;
      margin-top: 8px;
    }

    .btn.submitForm-btn {
      order: 2;
      margin-top: 0;
    }
  }
}