/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../config/variables-fns';

.externalPageContainer {
  background-color: white;

  // to fix the right Col
  .externalPageRow {
    height: calc(~'100vh - 20px');

    .loginImgCol,
    .contentCol {
      height: 100%;
    }

    .contentCol {
      overflow-y: auto;
      @media screen and (max-width:991px) {
        padding-right: 0;
      }
    }
  }

  .loginImgCol {
    @media screen and (max-width:991px) {
      display: none;
    }

    .loginImgWrapper {
      width: 100%;
      height: 100%;
      background: @gradientBg;

      .loginImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        -webkit-animation-duration: 3s;
        animation-duration: 3s;
      }
    }
  }

  .loginContentWrapper {
    padding: 70px;
    padding-right: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width:992px) and (max-width:1669px) {
      padding: 30px;
      padding-left: 0;
    }

    @media screen and (max-width:991px) {
      align-items: center;
      padding-right: 85px;
      .loginFormWrapper,
      .form-container {
        width: 100%;
      }
      .loginFormWrapper {
        display: flex;
        justify-content: center;
      }
    }
  }

  .sysLogoWrapper {
    width: 200px;
    height: 65px;
    margin-bottom: 48px;

    .sysLogo {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      object-position: left;
    }
  }

  .welcomeTextWrapper {
    margin-bottom: 44px;

    @media screen and (max-width:991px) {
      text-align: center;
    }
    .welcomeToText {
      font-size: 22px;
      font-weight: 500;
    }
    .pageTitle,
    .loginSubTitle {
      font-weight: 500;
      color: @mainText;
      line-height: 1.4;
    }

    .pageTitle {
      font-size: 30px;
      margin-bottom: 16px;
    }

    .loginSubTitle {
      font-size: 16px;
    }
  }

  // actual form 
  .loginFormWrapper {
    .data-input-label {
      font-size: 16px;
      padding-top: 0;
    }

    // inputs
    .data-input .form-control,
    .evc-btns-footer .btn {
      height: 48px;
      border-radius: 4px;
      font-size: 16px;
    }

    .data-input .form-control {
      padding: 16px;
      margin-bottom: 0 !important;
      &[type="password"] {
        padding-right: 42px;
      }
    }

    // show/hide password
    .togglePasswordBtn {
      position: absolute;
      right: 16px;
      top: 0;
      margin: 0;
      height: 48px;

      &.btn,
      &.btn:hover,
      &.btn:focus,
      &.btn:active {
        background-color: transparent;
      }

      .ev-eye,
      .ev-hide {
        color: @mainText;

        &::before {
          font-size: 18px;
        }
      }
    }

    // errors
    .warning-span {
      font-size: 14px;
      margin-top: 4px;
      display: block;
    }
  }

  // Page Alerts & Footer
  .alert {
    margin: 0;
    margin-bottom: 16px; //should be on the container not the component
    border: none;
    border-radius: 4px;
    color: white;

    &.alert-success {
      background-color: @success;

      .alertIcon {
        color: @success;
      }
    }

    &.alert-danger {
      background-color: @danger;

      .alertIcon {
        color: @danger;
      }
    }

    &.alert-info {
      background-color: @info;
      border: solid 1px @borders;

      .alertIcon {
        color: @mainText;
      }
    }

    .alertHeading {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .alertIcon {
        margin-right: 5px;
        background: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        margin-right: 8px;
      }

      .alertTitle {
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .alertBody {
      font-size: 16px;
    }
  }

  // login btn
  .evc-btns-footer {
    .btn {
      width: 100%;
    }

    .btn+.btn {
      margin-left: 0;
      margin-top: 8px;
    }

    .btn-cancel {
      border-width: 1px;
    }
  }

  .footer {
    left: 0;
  }
}

// info/show password btns 
.userRegistrationPage,
.externalResetPasswordPage {
  .infoBtn {
    color: @mainText;

    &.btn,
    &.btn:hover,
    &.btn:focus,
    &.btn:active {
      background-color: transparent !important;
    }
  }
}