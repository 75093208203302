/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../config/variables-fns';

.reset-password-wrapper {
  // for the internal resetPassword page only 
  padding-left: 0;
  min-height: calc(~'100vh - 90px');
  display: flex;
  align-items: center;
  border-radius: 4px;

  #page-content-wrapper {
    padding: 0 !important;
  }

  .welcome-text {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 24px;
  }

  .data-input .form-control,
  .evc-btns-footer .btn {
    height: 48px;
    border-radius: 4px;
    font-size: 16px;
  }

  .data-input .form-control {
    padding: 16px;
    margin-bottom: 0 !important;
    &[type="password"] {
      padding-right: 42px;
    }
  }

  // show/hide password
  .togglePasswordBtn {
    position: absolute;
    right: 16px;
    top: 0;
    margin: 0;
    height: 48px;

    &.btn,
    &.btn:hover,
    &.btn:focus,
    &.btn:active {
      background-color: transparent;
    }

    .ev-eye,
    .ev-hide {
      color: @mainText;

      &::before {
        font-size: 18px;
      }
    }
  }

  .infoBtn {
    color: @mainText;
    margin-bottom: 5px;

    &.btn,
    &.btn:hover,
    &.btn:focus,
    &.btn:active {
      background-color: transparent !important;
    }
  }

  .resetPasswordFormWrapper {
    background-color: @modeBgColor;
    padding: 30px 50px;

    .alert {
      margin-left: 0;
      margin-right: 0;
      color: @sysHeaderTextColor;
    }

    .alertHeading {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .alertIcon {
        display: flex;
        align-items: center;
      }

      .alertTitle {
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  .footer {
    left: 50px;
  }
}