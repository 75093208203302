/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../config/variables-fns';

.forgetPassLinkWrapper {
  margin-bottom: 40px;
  text-align: left;

  .forgetPassLink {
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    color: @mainColor;
  }
}