/* Copyright 2019 Greyskies. All Rights Reserved. */

@import 'config/variables-fns';

.warning-span {
  color: @lightDanger;
  font-size: 1.2rem;
}

.user-data-input-label{
  margin-top:6px;
}
.eventArgsRow{
  box-shadow: 0px 15px 0px -14px @borders;
  &:not(:first-child){ 
    padding-top: 5px;
  }
  .label {
    margin-bottom: 5px;
    min-width: 100%;
    line-height: 20px;
    white-space: initial;
    word-break: break-word;
  }
  .eventArgsValue{
    word-break: break-word;
    padding-left: 5px;
  }
}
.oss-btn-group{
  .dropdown-menu {
    border-radius: 0px;
    box-shadow: none;
    padding: 0;
    margin: 0;
    >li {
      >a {
        .transition(all 0.5s ease-in-out);
        &:hover {
          background: @darkGray;
          color: @mainText;
          outline: 0;
        }
        &:focus {
          background: @darkGray;
          color: @mainText;
          outline: 0;
        }
      }
    }
  }
  .dropdown-toggle {
    box-shadow: none;
    &:focus {
      span {
        color: @mainText;
      }
    }
  }
}
table .header-checkbox{
  padding: 13px 18px;
  padding-right: 38px;
}


.numbered-link{
  .numbered-link-label{
    font-weight: 700;
    cursor:default;
  }

  .numbered-link-link{
    a{
      cursor: pointer;
      text-decoration: underline;
      color:@darkGray;
      button{
        margin-bottom: 0px!important;
      }
    }
  }
}

.slideshow-enter {
  opacity: 0.01;
}

.slideshow-enter.slideshow-enter-active {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.slideshow-leave {
  opacity: 1;
}

.slideshow-leave.slideshow-leave-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in;
}

.scheduled-jobs-header{
  padding: 0 15px;
  border-bottom: 1px solid @midGray;

  .header-column{
    height: 45px;
    background-color: @darkGray;
    color: @mainText;
    padding-top: 10px;
    font-weight: 700;
  }
  .scheduled-job{
    border-bottom: 1px solid @midGray;
    &:last-of-type{
      border-bottom: none;
    }
    .row{
      margin: 0 0;
      &:hover{
        background: @mainColor;
        color:@mainText;
      }
    }
    .progress{
      margin: 0 0;
    }
    .scheduled-job-col{
      margin: 10px 0;
      padding: 0 15px;
    }

    .job-name{
      margin-top: 5px;
    }
    .emptyRow{
      text-align : center;
    }
  }
}

.scheduler-switch {
  .switch{
    .handle{
      left: 31px!important;
    }
    &.on{
      .handle{
        right: 35px!important;
        left: initial!important;
      }
    }
  }
}
.checkList{
  margin-left: 25px;
  .checkbox-group{
    margin:0px;
  }
}
.workflowPermission{
  .categoryTitle,
  .checkTitle{
    margin-top: 10px;
    font-weight: bold;
  }
  padding-bottom: 50px;
  .categoryTitle {
    margin-left: -15px;
  }
}
.parent span:first-of-type{
  margin-left:8.3%;
  padding-left: 15px;
}

.resolutionText{
  color:@mainText;
  cursor: pointer;
}

@media(max-height: 988px) {
  .home-scrollable-area{
    max-height: 600px;
  }
}
@media(max-height: 638px) {
  .home-scrollable-area{
    max-height: 300px;
  }
}
